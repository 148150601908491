import * as React from 'react'
import * as styles from './css/accountDeletion.module.styl'
import Layout from "../components/layout"
import Seo from "../components/seo"
import AccountDeletionStepList from "../components/AccountDeletionStepList"
import { graphql } from "gatsby"
import { widthInfo } from "../util/index.js"
import { Trans } from 'gatsby-plugin-react-i18next'

const width = widthInfo()

const AccountDeletion = () => {
  return (
    <Layout>
      <Seo title="Account Deletion" />
      <div className={styles.accountDeletion}>
        <div className={styles.accountDeletionBan}>
          <div className={styles.accountDeletionBanInner}>
            <div className={styles.accountDeletionBanTitle}>
              <Trans i18nKey="banner.title" />
            </div>
            <a aria-label="accountDeletion download" className={styles.accountDeletionBanButton} 
 href="https://www.barwaqtfintec.com/Pakistan_2.0.0.3048_googleplay_104488.apk"
 download
            ></a>
            {
              width <= 1024 ? (
                <a aria-label="index download" className={styles.mobileSeedcredLink} target="_blank" href="https://www.seedcredfintec.com/"></a>
              ) : null
            }
          </div>
        </div>
        <div className={styles.steps}>
          <AccountDeletionStepList />
        </div>
      </div>
    </Layout>
  )
}

export default AccountDeletion;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {ns: {in: ["accountDeletion", "header"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
