// extracted by mini-css-extract-plugin
export var stepHead = "AccountDeletionStepList-module--stepHead--s2Qme";
export var stepHeadBar = "AccountDeletionStepList-module--stepHeadBar--qSHyi";
export var stepHeadBarDot = "AccountDeletionStepList-module--stepHeadBarDot--vrr-4";
export var stepHeadBarLine = "AccountDeletionStepList-module--stepHeadBarLine--9Looq";
export var stepHeadStepNum = "AccountDeletionStepList-module--stepHeadStepNum--RH+VX";
export var stepImage = "AccountDeletionStepList-module--stepImage--JHTe1";
export var stepItem = "AccountDeletionStepList-module--stepItem--AZuQE";
export var stepItemContent = "AccountDeletionStepList-module--stepItemContent--IX2eZ";
export var stepItemContentDesc = "AccountDeletionStepList-module--stepItemContentDesc--Qgx5I";
export var stepItemList = "AccountDeletionStepList-module--stepItemList--QD4pn";
export var stepItemListItem = "AccountDeletionStepList-module--stepItemListItem--sZgBV";
export var stepList = "AccountDeletionStepList-module--stepList--Dai-9";
export var stepMain = "AccountDeletionStepList-module--stepMain--TGjaE";
export var stepMainDesc = "AccountDeletionStepList-module--stepMainDesc--XVvii";
export var tip = "AccountDeletionStepList-module--tip--dfnWh";
export var title = "AccountDeletionStepList-module--title--rK7H5";