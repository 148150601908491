import * as React from "react"
import * as styles from '../../pages/css/AccountDeletionStepList.module.styl'
import { graphql } from "gatsby"
import { useState } from "react"
import { StaticImage } from "gatsby-plugin-image"
import { widthInfo } from "../../util/index.js"
import { useI18next } from "gatsby-plugin-react-i18next"

const width = widthInfo()
const AccountDeletion = () => {
  const { t, language } = useI18next()
  let stepItemSteps = [
    {
      text: t('stepItemSteps.item1'),
    },
    {
      text: t('stepItemSteps.item2'),
    },
    {
      text: t('stepItemSteps.item3'),
    },
    {
      text: t('stepItemSteps.item4'),
    },
    {
      text: t('stepItemSteps.item5'),
    },
  ]

  const steps = [
    {
      title: t('steps.item1.title'),
      desc: t('steps.item1.desc')
    },
    {
      title: t('steps.item2.title'),
      tip: t('steps.item2.tip'),
      steps: stepItemSteps
    },
    {
      title: t('steps.item3.title'),
      desc: t('steps.item3.desc')
    },
  ]

  const [stepList] = useState(steps)
  const isBrowser = typeof document !== "undefined"
  let rootEl = null
  if (isBrowser) {
    rootEl = document.documentElement
  }
  return (
    <ul className={styles.stepList}>
      {
        stepList.map((step, index) => {
          if (step.steps) {
            return (
              <li key={index} className={styles.stepItem}>
                <div className={styles.title}>
                  <span>{`0${index + 1}`}</span>
                  <h2>{step.title}</h2>
                </div>
                <div className={styles.stepItemContent}>
                  <div className={styles.tip}>
                    <p>{step.tip}</p>
                  </div>
                  <div className={styles.stepItemList}>
                    <ul>
                      {
                        step.steps.map(({ text }, idx) => {
                          return width > 1024 ? (
                            <li key={idx} className={styles.stepItemListItem}>
                              <div className={styles.stepHead}>
                                <div className={styles.stepHeadStepNum}>{t(`Step ${idx + 1}`)}</div>
                                <div className={styles.stepHeadBar}>
                                  <div className={styles.stepHeadBarDot}></div>
                                  <div className={styles.stepHeadBarLine}></div>
                                </div>
                              </div>
                              <div className={styles.stepMain}>
                                <p className={styles.stepMainDesc} dangerouslySetInnerHTML={{ __html: text }}></p>
                                {
                                  language === 'en' ? (
                                    <>
                                    {
                                      idx === 0 ? (
                                        <StaticImage className={styles.stepImage} src="../../images/accountDeletion/step1.png" alt="Step 1" />
                                      )
                                      : idx === 1 ? (
                                        <StaticImage className={styles.stepImage} src="../../images/accountDeletion/step2.png" alt="Step 2" />
                                      )
                                      : idx === 2 ? (
                                        <StaticImage className={styles.stepImage} src="../../images/accountDeletion/step3.png" alt="Step 2" />
                                      )
                                      : idx === 3 ? (
                                        <StaticImage className={styles.stepImage} src="../../images/accountDeletion/step4.png" alt="Step 2" />
                                      )
                                      : (
                                        <StaticImage className={styles.stepImage} src="../../images/accountDeletion/step5.png" alt="Step 2" />
                                      )
                                    }
                                  </>
                                  ) : (
                                    <>
                                      {
                                        idx === 0 ? (
                                          <StaticImage className={styles.stepImage} src="../../images/accountDeletion/step1-rtl.png" alt="Step 1" />
                                        )
                                        : idx === 1 ? (
                                          <StaticImage className={styles.stepImage} src="../../images/accountDeletion/step2-rtl.png" alt="Step 2" />
                                        )
                                        : idx === 2 ? (
                                          <StaticImage className={styles.stepImage} src="../../images/accountDeletion/step3-rtl.png" alt="Step 2" />
                                        )
                                        : idx === 3 ? (
                                          <StaticImage className={styles.stepImage} src="../../images/accountDeletion/step4-rtl.png" alt="Step 2" />
                                        )
                                        : (
                                          <StaticImage className={styles.stepImage} src="../../images/accountDeletion/step5-rtl.png" alt="Step 2" />
                                        )
                                      }
                                    </>
                                  )
                                }
                              </div>
                            </li>
                          ) : (
                            <li key={idx} className={styles.stepItemListItem}>
                              <div className={styles.stepHead}>
                                <div className={styles.stepHeadBar}>
                                  <div className={styles.stepHeadBarDot}></div>
                                  <div className={styles.stepHeadBarLine}></div>
                                </div>
                              </div>
                              <div className={styles.stepMain}>
                                <div className={styles.stepHeadStepNum}>{t(`Step ${idx + 1}`)}</div>
                                <p className={styles.stepMainDesc} dangerouslySetInnerHTML={{ __html: text }}></p>
                                {
                                  language === 'en' ? (
                                    <>
                                    {
                                      idx === 0 ? (
                                        <StaticImage className={styles.stepImage} src="../../images/accountDeletion/step1.png" alt="Step 1" />
                                      )
                                      : idx === 1 ? (
                                        <StaticImage className={styles.stepImage} src="../../images/accountDeletion/step2.png" alt="Step 2" />
                                      )
                                      : idx === 2 ? (
                                        <StaticImage className={styles.stepImage} src="../../images/accountDeletion/step3.png" alt="Step 2" />
                                      )
                                      : idx === 3 ? (
                                        <StaticImage className={styles.stepImage} src="../../images/accountDeletion/step4.png" alt="Step 2" />
                                      )
                                      : (
                                        <StaticImage className={styles.stepImage} src="../../images/accountDeletion/step5.png" alt="Step 2" />
                                      )
                                    }
                                  </>
                                  ) : (
                                    <>
                                      {
                                        idx === 0 ? (
                                          <StaticImage className={styles.stepImage} src="../../images/accountDeletion/step1-rtl.png" alt="Step 1" />
                                        )
                                        : idx === 1 ? (
                                          <StaticImage className={styles.stepImage} src="../../images/accountDeletion/step2-rtl.png" alt="Step 2" />
                                        )
                                        : idx === 2 ? (
                                          <StaticImage className={styles.stepImage} src="../../images/accountDeletion/step3-rtl.png" alt="Step 2" />
                                        )
                                        : idx === 3 ? (
                                          <StaticImage className={styles.stepImage} src="../../images/accountDeletion/step4-rtl.png" alt="Step 2" />
                                        )
                                        : (
                                          <StaticImage className={styles.stepImage} src="../../images/accountDeletion/step5-rtl.png" alt="Step 2" />
                                        )
                                      }
                                    </>
                                  )
                                }
                              </div>
                            </li>
                          )
                        })
                      }
                    </ul>
                  </div>
                </div>
              </li>
            )
          } else {
            return (
              <li key={index} className={styles.stepItem}>
                <div className={styles.title}>
                  <span>{`0${index + 1}`}</span>
                  <h2>{step.title}</h2>
                </div>
                <div className={styles.stepItemContent}>
                  <p className={styles.stepItemContentDesc}>{step.desc}</p>
                </div>
              </li>
            )
          }
        })
      }
    </ul>
  )
}

export default AccountDeletion

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {ns: {in: ["accountDeletion", "header"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
